body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  width: 100%;

  -webkit-overflow-scrolling: touch;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Frutiger Light';
  src: url("./fonts/Frutiger LT 45 Light.ttf") format('truetype');
}

@font-face {
  font-family: 'Frutiger Roman';
  src: url("./fonts/Frutiger LT 55 Roman.ttf") format('truetype');
}

@font-face {
  font-family: 'Frutiger Bold';
  src: url("./fonts/Frutiger LT 65 Bold.ttf") format('truetype');
}
