.screen-customer, .screen.customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.screen > .body {
  text-align: center;
  font-family: "Frutiger Light";
  font-size: 16px;
  line-height: 20px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 0px;
}
/* ::-webkit-scrollbar:horizontal {
    height: 0px;
} */

.img-center {
  margin: 30px 0;
  /* display: block; */
  /* margin-top: 30px; */
}

.button {
  display: block;
  background-color: #646771;
  font-size: 24px;
  width: 260px;
  height: 55px;
  color: white;
  border-radius: 3pt;
  margin: 10px;
}

.button-text {
  margin: 0px;
  line-height: 55px;
  font-size: 20px;
  font-family: 'Frutiger Light';
  text-align: center;
}

.margin-left {
  margin-left: 10px
}

.margin-right {
  margin-right: 10px
}

.cancel-button {
  display: block;
  background-color: #696969;
  font-size: 24px;
  width: 260px;
  height: 55px;
  color: white;
  border-radius: 3pt;
  margin: 10px;
}

.button-red {
  display: block;
  background-color: #C4262E;
  font-size: 24px;
  width: 260px;
  height: 55px;
  color: white;
  border-radius: 3pt;
  margin: 10px;
}

.button-grey {
  display: block;
  background-color: #dfe3e6;
  font-size: 24px;
  width: 260px;
  height: 55px;
  color: black !important;
  border-radius: 3pt;
  margin: 10px;
}

.buttons {
  display: flex;
  align-items: stretch;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
  }
}

.same-row-buttons {
  display: flex;
  align-items: stretch;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .same-row-buttons {
    display: flex;
    align-items: stretch;
    align-items: center;
    justify-content: center;
    width: 270px;
  }
}

.logo {
  align-self: flex-end;
  margin-bottom: 20px;
  margin-right: 30px;
}

.screen > .header {
  color: black;
  font-size: 24px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  font-family: "Frutiger Roman";
}

.screen > .start-header {
  color: black;
  font-size: 20px;
  font-weight: 'bold';
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 0px;
  font-family: "Frutiger Roman";
}

a:link {
    text-decoration: none;
    color: white;
}

a:visited {
    text-decoration: none;
    color: white;
}

a:hover {
    text-decoration: none;
    color: white;
}

a:active {
    text-decoration: none;
    color: white;
}

/*


 */


 .box {
   /* padding: 10px; */
   margin-bottom: 10px;
 }

 .box > .title {
   font-family: "Frutiger Roman";
   font-size: 14px;
   color: #2B2B2B;
 }

 .box > .input-field {
   background-color: #FAFAFA;
   border-width: 1px;
   border-style: solid;
   border-radius: 5px;
   border-color: #CECECE;
   width: calc(100% - 10px);
   height: 53px;
   color: black;
   font-size: 16px;
   font-family: "Frutiger Bold";
   padding-left: 10px;
   margin-top: 5px;
 }

 .dropdown-field {
   background-color: #F0F0F0;
   border-width: 0px;
   width: 100%;
   height: 57px;
 }

 .help-block {
   color: red;
   display: block;
   font-size: 16px;
   font-family: 'Frutiger Light';
   height: 20px;
 }

 .calendar {
   position: absolute;
   z-index: 2;
 }

 .react-calendar__month-view__days__day--weekend {
   color: black !important;
 }

 .dd-wrapper {
   background-color: #FAFAFA;
   border-width: 1px;
   border-style: solid;
   border-radius: 5px;
   border-color: #CECECE;
   margin-top: 5px;
 }

 .dd-header {
   background-color: #FAFAFA !important;
   border-width: 0px;
   height: 55px;
   padding-left: 5px;
 }

 .dd-header-title {
   color: #2B2B2B;
   font-size: 16px;
   font-family: "Frutiger Bold";
   padding-left: 5px;
   margin: 0px;
 }

 .dd-search-background {
   background-color: #FAFAFA !important;
 }

 .dd-list {
   background-color: #FAFAFA !important;
 }

 .dd-list-item {
   text-overflow: ellipsis;
   font-size: 14px !important;
   font-family: "Frutiger Roman";
 }

 /*

 */

.startscreen-label {
  display: inline-block;
  width: 225px;
  font-size: 14px;
  font-family: 'Frutiger Light';
  color: #2B2B2B;
  margin: 0px;
}

.startscreen-input-field {
  background-color: #F0F0F0;
  border-width: 0px;
  width: 100%;
  height: 53px;
  color: #2B2B2B;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

.startscreen-input-field.capitalize {
  text-transform: uppercase;
}

.resend-code {
  font-size: 18px;
  font-family: 'Frutiger Light';
  color: #2B2B2B;
  margin-bottom: 95px;
  cursor: pointer;
  margin-bottom: 20px;
}

 /*


 */
.code-container {
  margin: 30px;
}

.code-field-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.code-field {
  width: 75px;
  height: 75px;
  margin: 8px;
  background-color: #F0F0F0;
  border-width: 0px;
  color: #2B2B2B;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
 }

 /*

 */

 .title {
   color: #2B2B2B;
   font-size: 14px;
   font-family: 'Frutiger Bold';
   margin-top: 2px;
 }

 .detail-body {
   color: #838383;
   font-size: 16px;
   font-family: "Frutiger Roman";
 }

 /*

 */

 .overlay-view {
   width: 100%;
   height: calc(100vh - 100px);
   overflow-y: auto;
   text-align: center;
 }

 .overlay-view > .header {
   color: black;
   font-size: 26px;
   font-family: "Frutiger Roman"
 }

 .overlay-view > .body {
   color: black;
   font-size: 16px;
   font-family: "Frutiger Light";
   font-weight: 200;
   padding-top: 80px;
   padding-bottom: 80px;
 }

 /*

 */

 .changed-data {
   color: #2b810b;
 }

 .banner {
   width: 100%;
   display: flex;
   min-height: 115px;
   height: 115px;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 0px;
 }

 .banner > img {
   margin-right: 90px;
 }

 .banner > div > p {
   margin: 0px;
   margin-left: 90px;
 }

@media only screen and (max-width: 768px) {
  .banner > div > p {
    margin: 0px;
    margin-left: 15px;
  }

  .banner > img {
    margin-right: 15px;
  }
}

.banner-title {
  font-family: "Frutiger Bold";
  font-size: 16px;
}

.banner-subtitle {
  font-family: "Frutiger Roman";
  font-size: 26px;
}

/*
  FAQ styles
*/

.Collapsible {
  width: 50%;
}

.faq_trigger {
  padding: 10px;
}

.faq_title {
  font-family: "OpenSans Bold";
  font-size: 24px;
  margin: 0px;
}

.faq_content {
  margin: 0px;
  padding: 20px;
  font-family: "OpenSans";
  font-size: 16px;
  background-color: #F5F5F5;
}

.is-open > .faq_trigger {
  background-color: #404040;
  color: white;
}

.faq-p {
  font-family: "Frutiger Light";
  font-size: 16px;
  color: black;
  cursor: pointer;
  text-align: center;
  color: black;
}

.scroll-copy {
  margin: 5vw;
  height: 50vh;
  overflow-y: auto;
}

.quick-link {
  font-family: "Frutiger Light";
  font-size: 12px;
  color: #2B2B2B;
  cursor: pointer;
  text-align: center;
}

.quick-link.text {
  margin-top: 12px;
  color: black;
}

.quick-link.web {
  display: none;
}

.footer-item {
  padding-left: 5px;
}

.footer-item.icon-right {
  padding-left: 0px;
  padding-right: 5px;
}

.footer-items {
  display: flex;
  flex-direction: row;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
}

.faq-doc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid black;
}

.faq-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 70%;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mobile-only {
  display: none;
}

@media only screen and (max-width: 768px) {
  .faq-wrapper {
    width: 100%;
  }

  .quick-link.web {
    display: block;
  }

  .footer-items {
    display: none;
  }

  .footer {
    justify-content: flex-end;
  }

  .Collapsible {
    width: 100%;
  }

  .code-field {
    width: 50px;
    height: 50px;
  }

  .overlay-view > .body {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .overlay-view > .buttons {
    margin-bottom: 20px;
  }

  .web-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}
